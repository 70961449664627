@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  overflow-x: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.loading {
  position: absolute;
  margin: 0 auto;
  height: 250px;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
  min-width: 100%;
}

.tr {
  height: 60px;
}

.td,
.th {
  padding: 0 20px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th {
  text-align: left;
  position: relative;
}

.thead > tr {
  //box-shadow: $primary-box-shadow;
  border-radius: 9px;
  //background-color: #fbfbfb;
}

.tbody > tr {
  border-bottom: 1px solid $primary-gray;
}

.tbody > tr:first-child {
  border-top: 1px solid $primary-gray;
}

//.tbody > tr:nth-child(even) {
//  box-shadow: $primary-box-shadow;
//  border-radius: 9px;
//  background-color: #fbfbfb;
//}

.sort_icon {
  position: absolute;
  top: 17px;
  right: 5px;
  color: $primary-gray;
}

.no_record_tr {
  > td {
    padding: 15px 20px;
    color: $primary-gray;
  }
}

.loading_icon_tr {
  padding: 15px 20px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.muiField {
  > div {
    border-radius: 50px;
  }
}

.page_count_field {
  width: 80px;
  margin-right: 10px !important;
  > div {
    border-radius: 5px;
  }
}

.pagination_button {
  background-color: $primary-color;
  box-shadow: $primary-box-shadow;
  height: 40px;
  width: 40px;
  @include flex-center;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  cursor: pointer;
}

.pagination {
  display: flex;
  height: 40px;
}
