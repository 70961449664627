@import '../../../scss/variables';

.employee_cell {
  display: flex;
  align-items: center;
  height: 65px;

  > span {
    margin-left: 10px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.action_button {
  margin: 20px;
}

.text_button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 35px;
  font-weight: 700;
  color: $primary-color;
  border: 2px solid $primary-color;
  padding: 0 10px;
}
