@import 'src/scss/mixins';
@import 'src/scss/variables';

.details {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.budget_card {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: lighten($primary-color, 70%);
  font-weight: 600;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;

  :nth-child(2) {
    color: $primary-color;
  }
}

.employees {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.user_name_base {
  display: flex;
  gap: 20px;
  border: 1px solid $primary-gray;
  border-radius: 2px;
  padding: 10px;
  width: fit-content;
  align-items: center;
}

.user_name {
  :nth-child(1) {
    font-weight: bold;
  }
  :nth-child(2) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
}

.user_action {
  color: $primary-color;
  cursor: pointer;
}

.employees_title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.allocate_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
}
