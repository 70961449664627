@import '../../../scss/variables';
@import '../../../scss/mixins';

.details {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.title {
  font-weight: 500;
  font-size: 20px;
}

.avatar {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 250px;
}

.avatar_details > p:nth-child(1) {
  font-weight: 500;
  font-size: 18px;
}

.avatar_details > p:nth-child(2) {
  color: $primary-gray-darker;
}

.budget_card {
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: lighten($primary-color, 70%);
  font-weight: 600;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;

  :nth-child(2) {
    color: $primary-color;
  }
}

.button {
  @include flex-center;
  height: 50px;
  width: 180px;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
}

.tabNav {
  margin-top: 20px;
  margin-bottom: 20px;
}
