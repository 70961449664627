@import '../../../scss/variables';
@import '../../../scss/mixins';

.base {
  display: flex;
  flex-direction: column;
}

.carousel {
  min-width: 500px;
  align-self: center;
}

.enroll_button {
  width: 300px;
  height: 60px;
  margin-top: 20px !important;
  align-self: flex-end;
}

.budget_details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.budget_card {
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: lighten($primary-color, 70%);
  font-weight: 600;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;

  :nth-child(2) {
    color: $primary-color;
  }
}
