@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  gap: 5px;
}

.icon_button {
  @include flex-center;
  height: 40px;
  width: 40px;
  //margin-right: 10px;
  background-color: $primary-color;
  cursor: pointer;
  border-radius: 30px;
  color: #ffffff;
  transition: all 300ms ease-out;
  &:hover {
    box-shadow: $primary-box-shadow;
    background-color: darken($primary-color, 15%);
  }
}

.icon {
}
