@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.employee_cell {
  display: flex;
  align-items: center;
  height: 65px;

  > span {
    margin-left: 10px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.action_button {
  margin: 20px;
}

.enroll_button {
  //width: 100%;
  margin-top: 20px;
  //height: 50px;
  align-self: flex-end;
  text-decoration: none;
}
