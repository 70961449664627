@import '../../../scss/variables';
@import '../../../scss/mixins';

.r_coaches {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  //overflow-y: scroll;
  height: 100vh;

  @include laptop {
    display: none;
  }
}

.coach_card_base {
  width: 250px;
  border: 1px solid $primary-gray;
  border-radius: 2px;
  padding: 20px;
  gap: 10px;
  display: grid;
  grid-template-rows: 50px auto 30px;
}

.coach_name_base {
  display: flex;
  gap: 10px;
}

.coach_name {
  :nth-child(1) {
    font-weight: bold;
  }
  :nth-child(2) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
  :nth-child(3) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
}

.coach_description {
  color: $primary-gray;
  margin-top: 10px;
}

.coach_actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
