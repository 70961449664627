@import 'src/scss/mixins';
@import 'src/scss/variables';

.employees {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.user_card_base {
  width: 450px;
  border: 1px solid $primary-gray;
  border-radius: 2px;
  padding: 20px;
  gap: 10px;
  display: grid;
  grid-template-rows: 50px auto 30px;
}

.user_name_base {
  display: flex;
  gap: 10px;
}

.user_name {
  :nth-child(1) {
    font-weight: bold;
  }
  :nth-child(2) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
  :nth-child(3) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
}

.user_description {
  color: $primary-gray;
  margin-top: 10px;
}

.user_actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 20px;
}

.user_remove_btn {
  color: #d21c1c;
  cursor: pointer;
}

.user_add_btn {
  color: $primary-color;
  cursor: pointer;
}

.button {
  @include flex-center;
  width: 150px;
  height: 40px;
  background-color: $primary-color;
  color: white;
  margin-top: 20px;
  align-self: flex-end;
  cursor: pointer;
}
