@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
  position: relative;
}

.close_button {
  position: absolute;
  top: -18px;
  right: -18px;
}

.title {
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
}

.icon_button {
  @include flex-center;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  background-color: $primary-color;
  cursor: pointer;
  border-radius: 30px;
  color: #ffffff;
  transition: all 300ms ease-out;
  &:hover {
    box-shadow: $primary-box-shadow;
    background-color: darken($primary-color, 15%);
  }
}
