@import '../../../../scss/variables';
@import '../../../../scss/mixins';

:export {
  chartColor1: $primary-color;
  chartColor2: lighten($primary-color, 10%);
  chartColor3: lighten($primary-color, 20%);
  chartColor4: lighten($primary-color, 30%);
}

.base {
  display: flex;
  flex-direction: column;
}

.budget_items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}

.data_item_wrapper {
  padding: 10px;
  border: 1px solid $primary-gray;
  width: 500px;

  > p {
    color: $primary-gray-darker;
    font-weight: 600;
  }

  @include tablet {
    width: 100%;
  }
}
