@import 'src/scss/mixins';
@import 'src/scss/variables';

:export {
  buttonBoxShadow: 0 13px 18px -4px transparentize($primary-color, 0.6);
}

.name {
  margin-left: 20px;
}

.icon {
  font-size: 20px;
}
