@import '../../scss/variables';
@import '../../scss/mixins';

$navbar-height: 90px;
$gray: #989898;

.base {
  display: grid;
  grid-template-columns: 250px 5fr;

  @include tablet {
    grid-template-columns: 1fr;
  }

  //@include phone {
  //  grid-template-columns: 1fr;
  //}
}

.logo {
  width: 120px;
  margin: 20px;
}

.menu_base {
  z-index: 10;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 15px 0 #00000014;
  transition: all 300ms ease-out;
  //overflow-y: scroll;
  height: 100vh;

  @include tablet {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: white;
    margin-left: -250px;
  }
}

.section_wrapper {
  overflow-y: scroll;
  height: 100%;
}

.menu_open {
  @include tablet {
    margin-left: 0;
  }
}

.menu_nav_top {
  display: flex;
  justify-content: center;
  height: 100px;

  // img {
  //   filter: grayscale(100%);
  // }
}

.menu_close_button {
  display: none;
  @include tablet {
    display: flex;
    width: 30px;
    height: 30px;
    margin: 20px;
    color: $primary-gray;
    align-self: flex-end;
  }
}

.menu_section {
  padding: 20px 40px;
}

.menu_item_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;

  > p {
    font-weight: bold;
    font-size: 20px;
  }

  > svg {
    color: $gray;
  }
}

.menu_item_active {
  color: $primary-color !important;
}

.collapse_item_wrapper {
  display: flex;
  align-items: center;
  color: $gray;
  cursor: pointer;
  margin: 20px 0;
  text-decoration: none;

  > svg {
    margin: 0 20px;
  }

  > p {
    font-weight: bold;
  }
}

.nav_bar {
  box-shadow: 13px 6px 15px 0 #00000014;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > svg {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 30px 20px;

    @include tablet {
      opacity: 1;
      width: 30px;
      height: 30px;
      color: $gray;
    }
  }
}

.nav_title {
  margin-left: 30px;
  font-size: 30px;
  font-weight: 600;

  @include tablet {
    margin-left: 70px;
    font-size: 25px;
  }

  @include phone {
    font-size: 18px;
  }
}

.nav_actions {
  display: flex;
  align-items: center;

  > svg {
    margin-right: 20px;
  }
}

.avatar_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  cursor: pointer;

  > div {
    color: $primary-color;
  }
}

.content_base {
  //background-color: #5dc152;
  height: 100vh;
  display: grid;
  grid-template-rows: $navbar-height auto;
}

.content {
  //height: calc(100vh - #{$navbar-height} - 20px);
  overflow: scroll;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
