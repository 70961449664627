@import '../../../../scss/variables';
@import '../../../../scss/mixins';

:export {
  chartColor1: $primary-color;
  chartColor2: lighten($primary-color, 10%);
  chartColor3: lighten($primary-color, 20%);
  chartColor4: lighten($primary-color, 30%);
}

.base {
  display: grid;
  //flex-direction: column;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  margin-top: 20px;

  grid-template-areas:
    'a b'
    'c d'
    'e d';

  @include tablet {
    grid-template-areas:
      'a a'
      'b b'
      'c c'
      'd d'
      'e e';

    //grid-template-columns: 1fr;
  }
}

.budget_items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}

.data_item_wrapper {
  box-sizing: border-box;
  //padding: 10px;
  border: 1px solid $primary-gray;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    color: $primary-gray-darker;
    font-weight: 600;
  }

  @include tablet {
    width: 100%;
  }
}

.nps_div {
  display: flex;
  gap: 10px;
  border: 1px solid $primary-gray;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.nps_breakdown {
  border: 1px solid $primary-gray;
  box-sizing: border-box;
  padding: 20px;
}

.nps_utilization {
  border: 1px solid $primary-gray;
  box-sizing: border-box;
  padding: 20px;
}

.nps_utilization > p {
  font-weight: 600;
  font-size: 16px;
}

.nps_content > p {
  font-weight: 600;
  font-size: 16px;
}

.nps_value {
  background: $primary-color;
  color: white;
  height: 50px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
}
