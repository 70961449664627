@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-areas: 'list content';
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin: 35px 0 0 35px;
}

.divider {
  //margin: 20px 40px 0 40px !important;
  margin: 20px 0 0 0 !important;
  background-color: $primary-color-lighter !important;
}

.list {
  grid-area: list;
}

.content {
  grid-area: content;
  padding: 20px;
  width: auto;
  overflow: auto;
}

.listItemIcon {
  @include flex-center;
  margin: 0 20px;
  color: #c4c4c4;
}

.listItemRoot {
  height: 60px;
  margin: 0 0 25px 0 !important;

  > span {
    color: $primary-color-darker;
  }
}

.listItemSelected {
  background-color: #f3f3f3 !important;
  color: $primary-color-darker !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &:after {
    content: '';
    height: 100%;
    width: 5px;
    background-color: $primary-color-darker;
  }
}

.listIcon {
  margin: 0 20px;
  color: #c4c4c4;
  width: 30px !important
}

.link{
  text-decoration: none;
  color: unset;
}

@media only screen and (max-width: 768px) {
  .base {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
            'list'
            'content';
  }

  .list {
    //display: none;
  }
}
