@import '../../../../scss/variables';

.base {
  display: flex;
  flex-direction: column;
}

//.info_cards{
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: space-between;
//}
//
//.card{
//  margin: 20px;
//  width: 250px;
//  height: 200px;
//}

.budget_items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.budget_card {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: lighten($primary-color, 70%);
  font-weight: 600;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;

  :nth-child(2),
  :nth-child(3),
  :nth-child(4) {
    color: $primary-color;
  }
}

.curriculum_budget_title {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-weight: 600;
  color: $primary-gray-darker;
}

.curriculum_budget_items {
  display: flex;
  flex-wrap: wrap;
}

.curriculum_card {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 10px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid $primary-gray;

  :nth-child(2),
  :nth-child(3),
  :nth-child(4) {
    color: $primary-color;
  }
}
