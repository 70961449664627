@import 'src/scss/variables';
@import 'src/scss/mixins';

.base{
  @include flex-center;
  height: 100vh;
}

.content{
  box-shadow: $primary-box-shadow;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;

  > h1{
    font-size: 100px;
    margin: 0;
    color: #505050;
  }

  > p {
    font-size: 20px;
    color: $primary-gray
  }
}
