.base {
  display: flex;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  align-items: center;
  /*padding: 15px 25px 15px 25px;*/
  padding-right: 20px;
  border-bottom: 1px solid #ccc;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 20px;
}
.base.premium {
  background: #484848;
  /*margin-bottom: 10px;*/
  color: #fff;
}

.imageBase {
  width: 20%;
  /* background-color: rgb(150, 84, 84); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 255px;
}
.badges_n_services {
  display: flex;
}
.serviceItem {
  border-radius: 15px;
  background: #cccccc;
  padding: 2px 15px;
  margin: 0 10px 7px 0;
  font-size: 12px;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
}
.premium .serviceItem {
  color: #000;
}
.badges {
  white-space: nowrap;
  /*margin-top: 10px;*/
}

.contentBase {
  padding: 0 15px;
  flex: 1;
  width: 64%;
}

.actionBase {
}
.premium .actionBase div {
  box-shadow: none;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  /*filter: blur(20px);*/
  /*border-radius: 100%;*/
  /*cursor: pointer;*/
}

.img1 {
  position: absolute;
  object-fit: fill;
}

.badge {
  font-size: 12px;
  color: white;
  padding: 3.5px 10px;
  margin: 10px;
  background-color: #007373;
  border-radius: 30px;
}

.nameBlock {
  display: flex;
  align-items: center;
}

.nameBlock p {
}

.available {
  display: flex;
  font-size: 10px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: rgb(37, 37, 37);
  height: 21px;
  padding: 0 7px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
}

.available div {
  height: 10px;
  width: 10px;
  background-color: #007373;
  border-radius: 100%;
}

.available span {
  margin-left: 5px;
}

.name {
  text-decoration: none;
  font-size: 19px;
  cursor: pointer;
  margin: 0;
  color: #222222;
}

.premium .name {
  color: white;
}

.from {
  font-size: 13px;
  color: #007373;
  font-weight: 600;
  margin: 0 30px;
}
.premium .from {
  color: #dcdcdc;
}

.expertSections {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

.expertSections p {
  margin: 0;
}

.keywords {
  font-weight: 500;
  font-size: 14px;
}
.description {
  font-size: 14px;
  font-weight: 300;
  margin: 10px 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.premium .description {
  -webkit-line-clamp: 3; /* number of lines to show */
}

.actionBase {
  white-space: nowrap;
  width: 16%;
  display: flex;
  flex-direction: column;
}

.actionBase p {
  font-weight: 600;
  margin: 0;
  justify-self: center;
  align-self: center;
  font-style: italic;
}

.author {
  display: flex;
}

.author > p {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  font-style: italic;
}

.price {
  font-size: 16px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #007373;
  color: white;
  border-radius: 2px;
  margin-top: 5px;
  cursor: pointer;
  text-decoration: none;
}

.serviceList {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

@media only screen and (max-width: 768px) {
  .actionBase {
    display: none;
  }
  .imageBase {
    border-radius: 7px;
    overflow: hidden;
    width: 210px;
    height: 210px;
    margin-top: 10px;
  }
  .contentBase {
    text-align: center;
  }
  .nameBlock {
    flex-direction: column;
  }
  .author {
    justify-content: center;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) {
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .base {
    flex-direction: column;
  }
  .badges_n_services {
    justify-content: center;
  }
  .contentBase {
    width: 100%;
    text-align: center;
  }
  .serviceList {
    text-align: center;
  }
}
