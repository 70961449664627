@import '../../../scss/variables';
@import '../../../scss/mixins';


.base{
  @include flex-column-center
}

.form{
  display: flex;
  flex-direction: column;
  width: 600px;
}

.title{
  font-size: 20px;
}

.input_field {
  margin-top: 20px !important;

  > div {
    border-radius: 30px;
  }
}

.submit_button {
  margin-top: 20px !important;
  height: 50px;
  width: 130px;
  align-self: flex-end;
  border-radius: 35px !important;
  color: white !important;
}
