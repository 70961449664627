@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
}

.coaches {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

//.coachCard {
//  min-height: 180px;
//  width: 300px;
//  background-color: #fff;
//  box-shadow: $primary-box-shadow;
//  border-radius: 10px;
//  margin: 20px;
//  padding: 10px;
//  display: grid;
//  grid-auto-columns: 70px auto;
//  grid-auto-rows: 70px auto;
//  grid-template-areas:
//    'profilePic name'
//    'desc desc'
//    'non btn';
//}
//
//.name {
//  grid-area: name;
//  display: flex;
//  align-items: center;
//}
//
//.profilePic {
//  grid-area: profilePic;
//  height: 60px;
//  width: 60px;
//  border-radius: 50px;
//  object-fit: cover;
//  margin: 0 10px 10pc 0;
//}
//
//.listings {
//  grid-area: desc;
//  display: flex;
//  flex-wrap: wrap;
//  gap: 10px;
//}

:export {
  primaryColor1: $primary-color;
  primaryColor2: lighten($primary-color, 10%);
  primaryColor3: lighten($primary-color, 20%);
  primaryColor4: lighten($primary-color, 30%);
}

.coach_card_base {
  width: 450px;
  border: 1px solid $primary-gray;
  border-radius: 2px;
  padding: 20px;
  gap: 10px;
  display: grid;
  grid-template-rows: 50px auto auto 25px;
}

.coach_name_base {
  display: flex;
  gap: 10px;
}

.coach_name {
  :nth-child(1) {
    font-weight: bold;
  }
  :nth-child(2) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
  :nth-child(3) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
}

.coach_description {
  color: $primary-gray;
  margin-top: 10px;
}

.coach_listings {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.coach_listing {
  height: 30px;
  width: 30px;
  //background-color: #007373;
  padding: 5px;
  border: 1px solid $primary-gray;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .coach_listing_default_img {
    filter: grayscale(100%);
  }
}

.coach_actions {
  display: flex;
  margin-top: 10px;
  gap: 20px;
}

.coach_action_btn {
  cursor: pointer;
}

.button {
  grid-area: btn;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.listing_item {
}

.filter_base {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 800px;

  @include tablet {
    grid-template-columns: 1fr;
  }
}

.filter_row {
  display: flex;
  gap: 20px;
}

.filter_range {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.field_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.submit_button {
  margin-top: 20px !important;
  margin-right: 20px !important;
}
