@import '../../../scss/variables';
@import '../../../scss/mixins';

//.card{
//
//  margin: 20px 10px
//}
//
//.categories{
//  display: flex;
//  flex-wrap: wrap;
//}
//
//.chips{
//  margin: 10px 10px 10px 0;
//}

.base {
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 600;
  color: $primary-gray-darker;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.category {
  padding: 10px;
  background-color: lighten($primary-color, 70);
  border-radius: 100px;
}

.description {
  color: $primary-gray;
}

.listings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.listing_card {
  display: grid;
  grid-template-columns: 200px auto 200px;
  gap: 10px;
  border: 1px solid $primary-gray;
  padding: 10px;

  @include tablet {
    grid-template-columns: 1fr;
  }
}

.listing_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.listing_details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 200px;
}

.listing_categories {
  display: flex;
  gap: 10px;

  > div {
    padding: 10px;
    background-color: lighten($primary-color, 70);
    border-radius: 100px;
    font-size: 12px;
  }
}

.listing_description {
  color: $primary-gray;
  overflow: hidden;
}

.listing_action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :nth-child(1) {
    font-weight: 600;
  }
}

.button {
  @include flex-center;
  height: 50px;
  width: 180px;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin-top: 5px;
}

.button2 {
  @include flex-center;
  height: 50px;
  width: 180px;
  //background-color: $primary-color;
  border: 1px solid $primary-color;

  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  box-sizing: border-box;
  color: black;
}

.budget_details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.budget_card {
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: lighten($primary-color, 70%);
  font-weight: 600;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;

  :nth-child(2) {
    color: $primary-color;
  }
}
