@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  @include flex-center;
  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
}
