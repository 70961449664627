@import 'src/scss/mixins';
@import 'src/scss/variables';


.base{
  @include flex-center;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  transition: all 300ms ease-out;

  &:hover {
    box-shadow: $primary-box-shadow,
  }
}
