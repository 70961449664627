@import 'src/scss/variables';
@import 'src/scss/mixins';

.form {
  display: flex;
  flex-direction: column;
}

.input_field {
  margin-top: 20px !important;

  //> div {
  //  border-radius: 30px;
  //}
}

.submit_button {
  margin-top: 20px !important;
  height: 40px;
  //width: 100px;
  align-self: flex-end;
}

.next_button {
  margin-top: 20px !important;
  height: 40px;
  //width: 100px;
  align-self: flex-end;
}

.user_option {
  display: flex;
  align-items: center;
  gap: 10px;

  > .user_option_avatar {
    height: 40px;
    width: 40px;
  }

  > .user_option_name {
    font-weight: 700;
    color: $primary-gray-darker;
  }

  > .user_option_bio {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $primary-gray;
  }
}

.listings {
  display: flex;
  flex-wrap: wrap;
}

.listing {
  //height: 100px;
  width: 250px;
  margin: 20px 20px 0 0;
  border-radius: 5px;
  position: relative;
  padding: 10px;
  border: 1px solid $primary-color;
}

.remove_icon {
  color: white;
  background-color: red;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  @include flex-center;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
}

.listing_title {
  color: $primary-gray;
  font-weight: 700;
}

.attendees_array {
  display: flex;
  flex-direction: column;
}

.attendee_item {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.select_field {
  margin-top: 20px;
}

.budget_input {
  margin-top: 20px !important;
  width: 300px;
}

.all_budget_base {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px 0;
}

.price_range {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.label {
  color: $primary-gray;
  font-weight: 400;
  margin-top: 20px;
}

.bulk_base {
  display: flex;
  flex-direction: column;
}
