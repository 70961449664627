@import 'src/scss/variables';

.tab_list{
  padding-top: 100px;
}

.create{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join{
  display: flex;
  flex-direction: column;
  align-items: center;
}
