@import 'src/scss/mixins';
@import 'src/scss/variables';

.form {
  display: flex;
  flex-direction: column;
}

.input_field {
  margin-top: 20px !important;
}

.submit_button {
  margin-top: 20px !important;
  height: 50px;
  width: 100px;
}
