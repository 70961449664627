@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
}

.employee_cell {
  display: flex;
  align-items: center;
  height: 65px;

  > span {
    margin-left: 10px;
  }
}

.actions {
  width: fit-content;
  display: flex;
  align-self: flex-end;
}

.roundButton {
  height: 70px;
  width: 70px;
  background-color: $primary-color;
  @include flex-center;
  color: white;
  font-size: 30px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: $primary-box-shadow;
}

.items {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.coach_card_base {
  width: 450px;
  border: 1px solid $primary-gray;
  border-radius: 2px;
  padding: 20px;
  gap: 10px;
  display: grid;
  grid-template-rows: 50px auto 30px;
}

.coach_name_base {
  display: flex;
  gap: 10px;
}

.coach_name {
  :nth-child(1) {
    font-weight: bold;
  }
  :nth-child(2) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
  :nth-child(3) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
}

.coach_description {
  color: $primary-gray;
  margin-top: 10px;
}

.coach_actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.coach_remove_btn {
  color: #d21c1c;
  cursor: pointer;
}
