@import 'src/scss/variables';
@import 'src/scss/mixins';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  //font-family: Verdana, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  //background-color: lighten($primary-color, 10%);
  //background-image: -webkit-linear-gradient(
  //  45deg,
  //  $primary-color 25%,
  //  transparent 25%,
  //  transparent 50%,
  //  $primary-color 50%,
  //  $primary-color 75%,
  //  transparent 75%,
  //  transparent
  //);
}
