@import 'src/scss/mixins';
@import 'src/scss/variables';

.steps {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.step {
  display: flex;
  align-items: center;
  gap: 10px;
}

.number_circle {
  @include flex-center;
  border-radius: 50%;
  border: 1px solid black;
  width: 35px;
  height: 35px;
  font-weight: 600;
}

.selected_number_circle {
  border: 1px solid $primary-color;
}

.completed_number_circle {
  background-color: $primary-color;
  color: white;
}

.title {
  font-weight: 600;
}

.selected_title {
  color: $primary-color;
}

.right_arrow {
  width: 40px;
  height: 40px;
  color: $primary-gray;
}
