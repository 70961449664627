@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.base {
  display: flex;
  flex-direction: column;
}

.enroll_button {
  width: 100%;
  margin: 20px 0 !important;
}

.button {
  @include flex-center;
  height: 50px;
  width: 180px;
  border-radius: 2px;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  align-self: flex-end;
}
