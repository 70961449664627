@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
}

.close_button {
  position: absolute;
  top: -18px;
  right: -18px;
}

.title {
  font-size: 20px;
  font-weight: 400;
  //margin-left: 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

.field {
  margin: 20px 0 0 0 !important;
}

.submit_button {
  margin-top: 20px;
  //height: 50px;
  //width: 100px;
  align-self: flex-end;
}

.all_roles {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.input_field {
  min-width: 250px;
}
