//$primary-color: #d6ad71;
//$primary-color: #d8ca9c;
//$primary-color: #d6ad71;
$primary-color: #007373;
$primary-color-darker: darken($primary-color, 10%);
$primary-color-lighter: lighten($primary-color, 30%);

$primary-gray: #c4c4c4;
$primary-gray-darker: #4f4f4f;

$primary-box-shadow: 0 4px 9px rgba(0, 0, 0, 0.09);

:export {
  primaryColor: $primary-color;
  primaryBoxShadow: $primary-box-shadow;
}
