@import '../../../scss/variables';

.money_details {
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
  color: #8e8e8e;
  margin: 50px 0;
  gap: 20px;
}

.money_detail_item {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  box-shadow: inset 0 3px 5px 0 #cacaca;
  border-radius: 8px;
  height: 50px;
}

.money_detail_item > div {
  padding: 0 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.text_button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 35px;
  font-weight: 700;
  color: $primary-color;
  border: 2px solid $primary-color;
  padding: 0 10px;
}
