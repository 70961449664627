@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin phone {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
