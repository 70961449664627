@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: grid;
  grid-template-columns: 150px auto 70px;
  grid-template-areas: 'logo links avatar';
}

.logo {
  grid-area: logo;
  margin: 10px;

  > img {
    width: 100%;
    filter: grayscale(100%);
  }
}

.links {
  grid-area: links;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.link {
  text-decoration: none;
  color: $primary-color;
  min-width: 60px;
  margin-right: 25px;
}

.avatar_base {
  @include flex-center;
  grid-area: avatar;
}

.avatar {
  cursor: pointer;
}
